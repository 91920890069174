'use client';

import Script from 'next/script';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import { useAppContext } from '@/contexts/AppContext';

export function ActiveCampaignLeadCapture({ scriptSrc }: { scriptSrc: string }): React.ReactNode {
  const { location } = useAppContext();
  const isHomepage = location.pathname === '/';
  const abTestActive = useActiveExperiment(ActiveExperiments.ACForm10Off, 'B');

  const shouldLoad = abTestActive && scriptSrc && isHomepage;

  if (!shouldLoad) return null;

  return <Script src={scriptSrc} charSet="utf-8" />;
}
