/* eslint-disable @next/next/no-before-interactive-script-outside-document */

'use client';

import Script from 'next/script';
import { Locale } from '@fixter/i18n';
import { useAppContext } from '@/contexts/AppContext';

interface KameleoonProps {
  readonly accountId: string;
  readonly trackSubdomains?: boolean;
  readonly antiFlicker?: {
    readonly enabled: boolean;
    readonly paths: string[];
    readonly loadingTimeout: number;
  };
}

export function KameleoonScript({ accountId, trackSubdomains, antiFlicker }: KameleoonProps): JSX.Element {
  const { locale, location } = useAppContext();
  const domain = location.origin;

  return (
    <>
      {antiFlicker?.enabled && antiFlicker.paths.includes(location.pathname) && (
        <Script id="kameleoon-af" strategy="beforeInteractive">
          {`
          var kameleoonLoadingTimeout = ${antiFlicker.loadingTimeout};
           const AFcss = '* { visibility: hidden !important; background-image: none !important; }',
           head = document.head || document.getElementsByTagName('head')[0],
           AFStyle = document.createElement('style');
           head.appendChild(AFStyle);
           AFStyle.type = 'text/css';
           if (AFStyle.styleSheet){
             AFStyle.styleSheet.cssText = AFcss;
           } else {
             AFStyle.appendChild(document.createTextNode(AFcss));
           }
           window.setTimeout(() => {
             head.removeChild(AFStyle);
           }, kameleoonLoadingTimeout);
        `}
        </Script>
      )}

      <Script
        id="kameleoon-script"
        src={`//${accountId}.kameleoon.eu/kameleoon.js`}
        strategy="beforeInteractive"
        async
      />

      {/* Kameleoon iframe. Needed to track a user if it's going on a different subdomain. */}
      {trackSubdomains && (
        <Script id="kameleoon-subdomain">
          {`
         // Change the value of this URL to point to your own URL, where the iFrame is hosted
        window.kameleoonIframeURL = "https://${domain}/kameleoon/iframe_Fixter${locale === Locale.FR_FR ? 'FR' : 'UK'}.html}"; // PASTE HERE ACTUAL IFRAME URL

        window.kameleoonLightIframe = false;
        var kameleoonIframeOriginElement = document.createElement("a");
        kameleoonIframeOriginElement.href = kameleoonIframeURL;
        window.kameleoonIframeOrigin = kameleoonIframeOriginElement.origin || (kameleoonIframeOriginElement.protocol + "//" + kameleoonIframeOriginElement.hostname);
        if (location.href.indexOf(window.kameleoonIframeOrigin) != 0)
        {
          window.kameleoonLightIframe = true;
          var kameleoonProcessMessageEvent = function(event)
        {
          if (window.kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0,9) == "Kameleoon")
        {
          window.removeEventListener("message", kameleoonProcessMessageEvent);
          window.kameleoonExternalIFrameLoaded = true;
          if (window.Kameleoon)
        {
          Kameleoon.Utils.runProtectedScript(event.data);
          Kameleoon.Analyst.load();
        }
          else
        {
          window.kameleoonExternalIFrameLoadedData = event.data;
        }
        }
        };
          if (window.addEventListener)
        {
          window.addEventListener("message", kameleoonProcessMessageEvent, false);
        }
          var iframeNode = document.createElement("iframe");
          iframeNode.src = kameleoonIframeURL;
          iframeNode.id = "kameleoonExternalIframe";
          iframeNode.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
          document.head.appendChild(iframeNode);
        }
        `}
        </Script>
      )}
    </>
  );
}
